import { useState } from 'react'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../services/firebase'

import './styles.css'
import toast from 'react-hot-toast'

function EmpresaRegister() {
    const [nomeEmpresa, setNomeEmpresa] = useState('')
    const [cnpj, setCnpj] = useState('')

    const handleRegisterEmpresa = () => {
        if(!nomeEmpresa || !cnpj) return
        
        const collRef = collection(db, 'empresas')

        // https://stackoverflow.com/questions/59412625/generate-random-uuid-javascript
        const generateUniqSerial = () => {
            return 'xxxx-xxxx-xxx-xxxx'.replace(/[x]/g, function (c) {
                //eslint-disable-next-line
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        try {
            addDoc(collRef, {
                nome: nomeEmpresa,
                cnpj,
                serial: generateUniqSerial(),
                blocked: false
            })
    
            setNomeEmpresa('')
            setCnpj('')

            toast.success('Empresa adicionada')
        } catch (err: any) {
            console.log(err)
            toast.error(err)
        }
    }
    
    return (
        <div>
            <input
                className="search-input"
                type="text"
                placeholder="empresa"
                value={nomeEmpresa}
                onChange={(e)=> setNomeEmpresa(e.target.value)}
            />
            <input
                className="search-input"
                type="text"
                placeholder="cnpj"
                value={cnpj}
                onChange={(e)=> setCnpj(e.target.value)}
            />
            <br/>
            <button
                className="cadastrar-empresa-button"
                onClick={handleRegisterEmpresa}
            >
                cadastrar
            </button>
        </div>
    )
}

export default EmpresaRegister