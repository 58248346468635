import { ReactNode } from 'react'

import './styles.css'

type kind = 'back' | 'front'

interface PanelProps {
    children?: ReactNode;
    className?: string;
    width?: number;
    kind: kind;
}

function Panel(props: PanelProps) {
    return (
        <div
            className={`panel ${props.className} ${props.kind}`}
            style={{width: props.width}}
        >
            {props.children}
        </div>
    )
}

export default Panel
