import { useNavigate } from 'react-router-dom'
import { useCallback, useContext, useState} from 'react'

import Panel from '../Panel'
import logoBackupShieldBranca from '../../assets/logo_backupshield_branca.png'

import './styles.css'
import { EmpresaContext } from '../../contexts/EmpresaContext'
import { useAuth } from '../../hooks/useAuth'

function Sidebar() {
    const { empresaNome, clearEmpresa, endusercnpj, setEndusercnpj } = useContext(EmpresaContext)
    const { roles } = useAuth()
    
    const [revenda] = useState('Shopseg')
    const [version] = useState('v1.0.1')

    const navigate = useNavigate()

    const showEmpresaList = () => {
        navigate('/empresa')
        clearEmpresa()
    }
    

    return (
        <Panel className="sidebar" kind='front'>
            <img width={300} src={logoBackupShieldBranca} alt="" />
            <hr/>
            <br/>
            <b>Empresa:</b>
            <p>{empresaNome}</p>
            <br/>
            <b>CNPJ:</b>
            <p>{endusercnpj.replace('|', '/')}</p>
            <br/>
            <b>Revenda:</b>
            <p>{revenda}</p>
            <br/>
            <b>Roles:</b>
            <ul>
                {roles?.map(r => <li>{r}</li>)}
            </ul>
            <br/>
            <div>
                <button
                        className="button-add-empresa"
                        onClick={showEmpresaList}
                    >
                        lista de clientes
                </button>
            </div>
            <div className="version-container">
                <p>{version}</p>
            </div>
        </Panel>
    )
}

export default Sidebar
