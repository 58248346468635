import { DataSnapshot, onValue, ref, update } from 'firebase/database'
import { useCallback, useEffect, useState } from 'react'
import { realtimeDb } from '../../services/firebase'
import './styles.css'

interface ServerPerformanceProps {
    empresaId: string
}

function ServerPerformance({empresaId}: ServerPerformanceProps) {
    const [cpu, setCpu] = useState('')
    const [hdd, setHdd] = useState('')
    const [ram, setRam] = useState('')
    const [restart, setRestart] = useState(false)

    const newSnap = (snap: DataSnapshot) => {
        if(snap.val() == null) return
        if(snap.val().cpu == null) return
        if(snap.val().hdd == null) return
        if(snap.val().ram == null) return
        if(snap.val().restart == null) return

        const { cpu, hdd, ram, restart } = snap.val()
        setRestart(restart)
        setCpu(cpu)
        setHdd(Number(hdd/1000/1000/1000).toFixed(2))
        setRam(ram)
    }
    
    useEffect(() => {
        // do EVERYTHING to do the least amount of
        // requests as possible
        if(!empresaId) return
        const unsub = onValue(ref(realtimeDb, `empresas/${empresaId}`), newSnap)
        return () => {
            unsub()
        }
    }, [empresaId])

    const setRealtimeDbRestart = useCallback(async () => {
        try {
            await update(ref(realtimeDb, `empresas/${empresaId}`), {
                restart: true
            })
            setRestart(true)
        } catch (err) {
            console.log(err)
        } finally {
            setRestart(false)
        }
    }, [empresaId])
    
    return (
        <div className='server-performance-container'>
            <button
                onClick={setRealtimeDbRestart}
                disabled={restart}
                className='performance-button'
            >
                reiniciar
            </button>
            <i className="fa fa-server" aria-hidden="true"></i>
            <h1>cpu: {cpu}%</h1>
            <i className="fa fa-hdd-o" aria-hidden="true"></i>
            <h1>hd: {hdd}</h1>
            <i className="fa fa-battery-half" aria-hidden="true"></i>
            <h1>ram: {ram}mb</h1>
        </div>
    )
}

export default ServerPerformance
