import {
    createContext,
    ReactNode,
    useEffect,
    useState,
    useCallback
} from 'react'
import {
    getAuth,
    signOut,
    signInWithEmailAndPassword,
    onAuthStateChanged
} from 'firebase/auth'
import toast from 'react-hot-toast'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../services/firebase'

type User = {
    id: string
    name: string
    avatar: string
    email: string
}

type AuthContextType = {
    user: User | undefined
    roles: string[]
    loginWithEmailAndPass: (email: string, pass: string) => Promise<void>
    logOut: () => Promise<void>
}

type AuthContextProviderProps = {
    children: ReactNode
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthContextProvider(props: AuthContextProviderProps) {
    const usersRef = collection(db, 'users')
    
    const auth = getAuth()
    const [user, setUser] = useState<User>()
    const [roles, setRoles] = useState<string[]>([])

    useEffect(() => {
        const unsubAuth = onAuthStateChanged(auth, user => {
            if(user) {
                const { uid, displayName, photoURL, email } = user
                setUser({
                    id: uid || '',
                    name: displayName || '',
                    avatar: photoURL || '',
                    email: email || ''
                })
            }
        })
        return () => {
            unsubAuth()
        }
    //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(!user) return
        if(!user.id) return

        const q = query(usersRef, where('uid', '==', user.id))
		getDocs(q).then((snap: any) => {
            setRoles(snap.docs[0].data().roles)
		}).catch(err => console.log(err))
    }, [user, user?.id, usersRef])

    const loginWithEmailAndPass = useCallback(async (email: string, pass: string) => {
        const result = await signInWithEmailAndPassword(auth, email, pass)
        if(result.user) {
            const {email, displayName, photoURL, uid} = result.user

            if(!email) {
                throw new Error('this is impossible to occur.')
            }

            setUser(prevUser => {
                return {
                    id: uid || '',
                    name: displayName || '',
                    avatar: photoURL || '',
                    email: email || ''}
                }
            )
        }
    }, [auth])

    async function logOut() {
        try {
            await signOut(auth)
            setUser(undefined)
            toast.success('successfully logged out')
        } catch(err) {
            toast.error('failed to log out')
        }
    }

    return (
        <AuthContext.Provider value={{user, roles, loginWithEmailAndPass, logOut}}>
            {props.children}
        </AuthContext.Provider>
    )
}
