import { getDocs, query, where } from 'firebase/firestore'
import { ref, update } from 'firebase/database'
import { useCallback, useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import BackupList from '../../components/BackupList'
import Panel from '../../components/Panel'
import ServerPerformance from '../../components/ServerPerformance'
import Sidebar from '../../components/Sidebar'
import { realtimeDb } from '../../services/firebase'
import './styles.css'
import ServerConfiguration from '../../components/ServerConfiguration'
import Schedule from '../../components/Schedule'

import { EmpresaContext } from '../../contexts/EmpresaContext'

let intervalId: NodeJS.Timer

function Dashboard() {
	const { empresasRef, endusercnpj } = useContext(EmpresaContext)
    const [empresaId, setEmpresaId] = useState('')

    const updateBlockSending = useCallback(async (state: boolean) => {
        if(!empresaId || empresaId === '') return
        await update(ref(realtimeDb, `empresas/${empresaId}`), {
            blockSending: state
        })
    }, [empresaId])

    const getData = useCallback(async () => {
        const q = query(empresasRef, where('cnpj', '==', endusercnpj?.replace('|', '/')))
        const snap = (await getDocs(q)).docs
        setEmpresaId(snap[0].id)
    }, [endusercnpj])

    useEffect(() => {
        updateBlockSending(false)
        return () => {
            updateBlockSending(true)
        }
    }, [empresaId, updateBlockSending])


    useEffect(() => {
        getData()

        // send lastPing from time to time (1min)
        intervalId = setInterval(async () => {
            if(!empresaId) return
            await update(ref(realtimeDb, `empresas/${empresaId}`), {
                lastPing: new Date()
            })
        }, 60000)

        return () => {
            clearInterval(intervalId)
        }
    // eslint-disable-next-line
    }, [])
    
    return (
        <div className='dashboard-container'>
            <Sidebar />
            <Panel className='info-container' kind='back'>
                <BackupList />
                <h1>desempenho do servidor</h1>
                <Panel kind='front'>
                    <ServerPerformance empresaId={empresaId}/>
                </Panel>
                <Panel kind='front'>
                    <ServerConfiguration empresaId={empresaId} />
                </Panel>
                <Panel kind='front'>
                    <Schedule />
                </Panel>
            </Panel>
        </div>
    )
}

export default Dashboard
