import React, { useState, useCallback, useEffect } from 'react'
import './style.css'
import AnimatedLoginSvg from './home-animated.svg'
import toast from 'react-hot-toast'
import { useAuth } from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'

import logoBackupShieldBranca from '../../assets/logo_backupshield_branca.png'

export default function LoginPage() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const {user, loginWithEmailAndPass} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if(user) navigate('/')
    //eslint-disable-next-line
    }, [user])

    const handleSubmit = useCallback(async (e: any) => {
        e.preventDefault()
        try{
            // dont enforce login with @backupshield.com.br
            // you can just use the first part of it
            const pos = username.indexOf('@')
            let userParsed: string = ''
            if(pos !== -1)
                userParsed = username.slice(0, pos)
            userParsed += username + '@backupshield.com.br'

            await loginWithEmailAndPass(userParsed, password)
            toast.success('Logged in')
        } catch (err: any) {
            console.log(err)
            toast.error(err.message)
        }
    }, [username, password, loginWithEmailAndPass])

    return (
        <div className="main-login">
            <div className="left-side">
                <h1>Faça login<br /> e gerencie seus backups</h1>
                <img className="left-login-image" src={AnimatedLoginSvg} alt="" />
            </div>
            <div className="right-side">
                <img width="300" src={logoBackupShieldBranca} alt="" />
                <div className="card-login">
                    <h1>LOGIN</h1>
                    <form style={{width: '70%'}} onSubmit={handleSubmit}>
                        <div className="textfield">
                            <label htmlFor="usuario">Usuário</label>
                            <input
                                type="text"
                                name="usuario"
                                placeholder="Usuário"
                                id="usuario"
                                onChange={
                                    (e) => setUsername(e.target.value)
                                }
                            />
                        </div>
                        <div className="textfield">
                            <label htmlFor="senha">Senha</label>
                            <input
                                type="password"
                                name="senha"
                                placeholder="Senha"
                                id="senha"
                                onChange={
                                    (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
                                }
                            />
                        </div>
                        <button className="btn-login" type="submit">Login</button>
                    </form>
                </div>
            </div>
        </div>
    )
} 
