/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext, useCallback } from 'react'
import Panel from '../Panel'
import './styles.css'
import '../../styles/toast-button.css'

import { db } from '../../services/firebase'
import { updateDoc, doc, getDoc } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import {EmpresaContext} from '../../contexts/EmpresaContext'
 
interface EmpresaSearchItemProps {
    id: string;
    nome: string;
    cnpj: string;
    blocked: boolean;
    key?: React.Key;
    serial: string;
    lastBackup?: string;
    size?: number;
}

function EmpresaSearchItem({id, nome, cnpj, blocked, serial, lastBackup, size}: EmpresaSearchItemProps) {
	const { setEndusercnpj } = useContext(EmpresaContext)
    const navigate = useNavigate()
    
    const handleOpenDashboard = useCallback(() => {
        const parsedCnpj = cnpj.replace('/','|')
		setEndusercnpj(parsedCnpj)
        navigate('/dashboard/' + parsedCnpj)
    }, [cnpj, navigate])

    const handleDownloadLastBackup = useCallback(async () => {
        const docRef = doc(db, 'empresas', id)
        const document = (await getDoc(docRef)).data()

        if(!document?.backups) return
        const sorted = document.backups.sort((a: any, b: any) => {
            return a.finishTime._seconds - b.finishTime._seconds
        })
        
        const storage = getStorage()
        const url = await getDownloadURL(ref(storage, sorted[sorted.length - 1].URL))
        // toast('URL do backup:\n' + url, { duration: 20000 })
        toast((t) => (
            <span>
                URL do backup: <a href={url}>clique aqui</a>
                <br />
                <button className='toast-button' onClick={() => toast.dismiss(t.id)}>
                    fechar
                </button>
            </span>
        ), { duration: 40000 })
        console.log(url)
    }, [id])

    const handleBlockAccount = useCallback(async () => {
        const docRef = doc(db, 'empresas', id)
        await updateDoc(docRef, {
            blocked: !blocked
        })
    }, [id, blocked])
    
    return(
        <Panel className="empresa-item" kind='front'>
            <div>
                <p>{nome} - {cnpj}</p>
                <p style={{fontSize: 15}}>{serial} - {lastBackup} - {size} GB</p>
            </div>
            <div style={{display: 'flex', gap: 30}}>
                <i onClick={handleDownloadLastBackup} className="fa fa-database button-icon" aria-hidden="true"></i>
                <i onClick={handleOpenDashboard} className={`fa fa-cloud button-icon`} aria-hidden="true"></i>
                <i onClick={handleBlockAccount} className={`fa fa-ban button-icon ${blocked && 'blocked-icon'}`} aria-hidden="true"></i>
            </div>
        </Panel>
    )
}

export default EmpresaSearchItem

