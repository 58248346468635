import {
	createContext,
	ReactNode,
	SetStateAction,
	Dispatch,
	useState,
	useEffect,
	useCallback
} from 'react'

import {
	collection,
	CollectionReference,
	getDocs,
	query,
	where
} from 'firebase/firestore'
import { db } from '../services/firebase'

type EmpresaContextProviderProps = {
	children: ReactNode
}

type EmpresaContextType = {
	endusercnpj: string
	setEndusercnpj: Dispatch<SetStateAction<string>>
	clearEmpresa: () => void
	empresasRef: CollectionReference
	empresaId: string
	empresaNome: string
}

export const EmpresaContext = createContext({} as EmpresaContextType)

function EmpresaContextProvider(props: EmpresaContextProviderProps) {
	const [endusercnpj, setEndusercnpj] = useState('')
	const [empresaId, setEmpresaId] = useState('')
	const [empresaNome, setEmpresaNome] = useState('')
	const empresasRef = collection(db, 'empresas')

	const clearEmpresa = useCallback(() => {
		setEmpresaId('')
		setEmpresaNome('')
		setEndusercnpj('')
	}, [])

	useEffect(() => {
		// if there is not endusercnpj
		// that means the admin has not clicked yet
		// in the dashboard icon
		// TODO: when the user login, set
		// endusercnpj automatically
		if(!endusercnpj) return

        const q = query(empresasRef, where('cnpj', '==', endusercnpj?.replace('|', '/')))
		getDocs(q).then(snap => {
			setEmpresaId(snap.docs[0].id)
			setEmpresaNome(snap.docs[0].data().nome)
		}).catch(err => console.log(err))
	}, [empresasRef, endusercnpj])

	return (
		<EmpresaContext.Provider value={{endusercnpj, setEndusercnpj, empresasRef, empresaId, empresaNome, clearEmpresa}}>
			{ props.children }
		</EmpresaContext.Provider>
	)
}

export default EmpresaContextProvider

