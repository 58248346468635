import { collection, doc, getDocs, query, updateDoc, where, arrayRemove, arrayUnion } from 'firebase/firestore'
import { useCallback, useEffect, useState, useContext } from 'react'
import toast from 'react-hot-toast'
import {EmpresaContext} from '../../contexts/EmpresaContext'
import { db } from '../../services/firebase'
import './styles.css'

interface ScheduleObj {
    hour: string
    minute: string
}

const empresasRef = collection(db, 'empresas')
function Schedule() {
	const { endusercnpj: cnpj, empresaId } = useContext(EmpresaContext)
    const [minute, setMinute] = useState('')
    const [hour, setHour] = useState('')
    const [schedules, setSchedules] = useState<ScheduleObj[]>()

    const getData = useCallback(async () => {
        if(!cnpj) return;
        const q = query(empresasRef, where('cnpj', '==', cnpj?.replace('|', '/')))
        const snap = (await getDocs(q)).docs
        const sched = snap[0].data().schedules

        if(!sched) {
            toast.error('Não existe nenhum agendamento')
            return
        }

        setSchedules(sched)
        
    }, [cnpj])

    const deleteSched = useCallback(async (minute: string, hour: string) => {
        const docRef = doc(db, 'empresas', empresaId)
        try {
            await updateDoc(docRef, {
                schedules: arrayRemove({minute, hour})
            })
            toast.success('Removido com sucesso')
            getData()
        } catch (err: any) {
            toast.error(err)
        }
    }, [empresaId, getData])

    const sendSchedule = useCallback(async () => {
        if(!minute || !hour) {
            toast.error('Todos os campos devem ser preenchidos')
            return
        }

        const docRef = doc(db, 'empresas', empresaId)
        try {
            await updateDoc(docRef, {
                schedules: arrayUnion({minute, hour})
            })
            toast.success('Agendado com sucesso')
            getData()
        } catch (err: any) {
            toast.error(err)
        } finally {
            setHour('')
            setMinute('')
        }
    }, [empresaId, getData, hour, minute])

    useEffect(() => {
        getData()
    // eslint-disable-next-line
    }, [])
    
    return (
        <div style={{padding: 20}}>
            <h1>agendador</h1>
            <input
                className="schedule-input"
                type="number"
                placeholder='hora'
                value={hour}
                onChange={(e) => setHour(e.target.value)}
            />
            <input
                className="schedule-input"
                type="number"
                placeholder='minuto'
                value={minute}
                onChange={(e) => setMinute(e.target.value)}
            />
            <button onClick={sendSchedule} className="schedule-button">agendar</button>
            {schedules &&
                schedules.map((s, index) =>
                    <p key={index}>
                        <span onClick={()=>deleteSched(s.minute, s.hour)} style={{color: 'red', cursor: 'pointer'}}>DEL </span>
                        Hora: <b>{s.hour}</b> Minuto: <b>{s.minute}</b>
                    </p>
                )
            }
        </div>
    )
}

export default Schedule
