import { useCallback, useEffect, useState } from "react";
import EmpresaRegister from "../EmpresaRegister";
import Panel from "../Panel";

import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../services/firebase";

import "./styles.css";
import EmpresaSearchItem from "../EmpresaSearchItem";

interface Empresa {
  id: string;
  nome: string;
  cnpj: string;
  blocked: boolean;
  serial: string;
  lastBackup?: string;
  backupSize?: number;
}

const collRef = collection(db, "empresas");
let unsub: any;
function EmpresaSearch() {
  const [register, setRegister] = useState(false);
  const [empresas, setEmpresas] = useState<Empresa[]>([]);
  const [search, setSearch] = useState("");

  const getLastBackupDate = (e: any) => {
    if (!e.backups) return "nenhum backup";
    const sorted = e.backups.sort((a: any, b: any) => {
      return a.finishTime._seconds - b.finishTime._seconds;
    });
    const last = sorted[sorted.length - 1];
    // removido o .seconds
    return new Date(last.finishTime * 1000).toLocaleString("pt-br");
  };

  const getLastBackupSize = (e: any) => {
    if (!e.backups) return 0;
    const sorted = e.backups.sort((a: any, b: any) => {
      return a.finishTime._seconds - b.finishTime._seconds;
    });
    const last = sorted[sorted.length - 1];
    return Number((Number(last.backupSize) / 1024 / 1024 / 1024).toFixed(3));
  };

  const getEmpresas = useCallback(async () => {
    const snapshot = await getDocs(collRef);
    const tempEmp: Empresa[] = [];
    snapshot.docs.forEach((doc) => {
      const e: any = doc.data();
      const emp: Empresa = {
        id: doc.id,
        nome: e.nome,
        cnpj: e.cnpj,
        blocked: e.blocked,
        serial: e.serial,
        lastBackup: getLastBackupDate(e),
        backupSize: getLastBackupSize(e),
      };
      tempEmp.push(emp);
    });
    setEmpresas(tempEmp);
  }, []);

  useEffect(() => {
    unsub = onSnapshot(collRef, (snapshot) => {
      const tempEmp: Empresa[] = [];
      snapshot.docs.forEach((doc) => {
        const e: any = doc.data();
        console.log(e, "11");
        const emp: Empresa = {
          id: doc.id,
          nome: e.nome,
          cnpj: e.cnpj,
          serial: e.serial,
          blocked: e.blocked,
          // lastBackup: getLastBackupDate(e),
          lastBackup: getLastBackupDate(e),
          backupSize: getLastBackupSize(e),
        };
        tempEmp.push(emp);
      });
      setEmpresas(tempEmp);
    });
    getEmpresas();
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Panel className="panel-container" kind="back">
      <Panel className="search-container" kind="front">
        <div style={{ display: "flex", width: "100%", gap: 30 }}>
          <button
            className="cadastrar-button"
            onClick={() => setRegister(!register)}
          >
            {register ? "cancelar" : "cadastrar"}
          </button>
          <button className="cadastrar-button fullwidth">
            {/* pesquisa */}
            <input
              autoComplete="off"
              className="search-input"
              type="text"
              name="pesquisa"
              id="pesquisa"
              placeholder="pesquisar"
              onChange={(e) => setSearch(e.target.value)}
            />
          </button>
        </div>
        {register && <EmpresaRegister />}
      </Panel>
      <div className="empresa-item-container">
        {
          // filter empresas if 'search' is not empty
          search
            ? empresas
                .filter(
                  (e) =>
                    e.nome.toLowerCase().includes(search.toLowerCase()) ||
                    e.cnpj.includes(search.toLowerCase())
                )
                .map((e) => (
                  <EmpresaSearchItem
                    id={e.id}
                    serial={e.serial}
                    nome={e.nome}
                    cnpj={e.cnpj}
                    key={e.id}
                    blocked={e.blocked}
                    lastBackup={e.lastBackup}
                    size={e.backupSize}
                  />
                ))
            : empresas.map((e) => (
                <EmpresaSearchItem
                  id={e.id}
                  serial={e.serial}
                  nome={e.nome}
                  cnpj={e.cnpj}
                  key={e.id}
                  blocked={e.blocked}
                  lastBackup={e.lastBackup}
                  size={e.backupSize}
                />
              ))
        }
      </div>
    </Panel>
  );
}

export default EmpresaSearch;
