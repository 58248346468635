import './styles.css'
import ComputerLain from '../../assets/lain404/c.gif'
import RealMeLain from '../../assets/lain404/r.gif'
import { useState } from 'react'

const random = Math.floor(Math.random() * 5)
const currentImg = [ComputerLain, RealMeLain][random]

function NotFound() {
    const [switcher, setSwitcher] = useState(false)
    return (
        <div
            className="notfound-container"
            onClick={() => setSwitcher(!switcher)}
        >
            {
                switcher === false ?
                    <div className="h1404" style={{cursor: currentImg ? 'pointer' : 'default'}}>
                        <h1 className='glitch'>
                            <span aria-hidden="true">http404</span>
                            http404
                            <span aria-hidden="true">http404</span>
                        </h1>
                    </div>
                : <img style={{width: '100vw'}} src={currentImg} alt="" />
            }
        </div>
    )
}

export default NotFound
