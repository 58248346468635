import { useCallback, useContext } from 'react'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import './styles.css'
import {EmpresaContext} from '../../contexts/EmpresaContext'
import toast from 'react-hot-toast'

interface BackupItemProps {
    id: string;
    backupDate: string;
    backupSize: string;
}

function BackupItem({id, backupDate, backupSize}: BackupItemProps) {
	const { endusercnpj } = useContext(EmpresaContext)
	const generateBackupUrl = useCallback(async (): Promise<string> => {
		try {

			const storage = getStorage()
			console.log(storage)
			// const backupRef = ref(storage, '/backups/' + endusercnpj +'/c:\\goshield\\'+ id)
			const backupRef = ref(storage, id)
			const url = await getDownloadURL(backupRef)
			return url
		} catch (err: any) {
			toast.error(err.message)
			return ''
		}
	}, [endusercnpj, id])

	const downloadBackup = useCallback(async () => {
		const anchor = document.createElement('a')
		anchor.href = await generateBackupUrl()
		anchor.click()
	}, [generateBackupUrl])

	const copyUrlToClipboard = useCallback(async () => {
		const url = await generateBackupUrl()

		if(url) toast.success('URL copiada')
		else toast.error('Nenhuma URL foi gerada')

		navigator.clipboard.writeText(url)
	}, [generateBackupUrl])

	const deleteBackup = useCallback(async () => {
		toast.error('Sem permissão para excluir!')
		// TODO: implement this
		// const storage = getStorage()
		// const backupRef = ref(storage, '/backups/' + endusercnpj + id)
		// try {
		// 	await deleteObject(backupRef)
		// 	toast.success('Backup deletado com sucesso')
		// } catch(err: any) {
		// 	toast.error(err.message)
		// }
	}, [])

    return (
        <div className="backup-item-container">
            <div>
                <p>| <b>{backupDate}</b></p>
                <p>| {id.slice(id.lastIndexOf('\\') + 1)}</p>
                <p>| {backupSize}</p>
            </div>
            <div style={{display: 'flex', gap: 15}}>
                <i onClick={copyUrlToClipboard} className="fa fa-clipboard button-icon" aria-hidden="true"></i>
                <i onClick={downloadBackup} className="fa fa-download button-icon" aria-hidden="true"></i>
                <i onClick={deleteBackup} className="fa fa-times button-icon disabled-button" aria-hidden="true"></i>
            </div>
        </div>
    )
}

export default BackupItem
