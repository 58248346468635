import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import './styles.css'
import { db } from '../../services/firebase'
import toast from 'react-hot-toast'

interface ServerConfigurationProps {
    empresaId: string
}

function ServerConfiguration({empresaId}: ServerConfigurationProps) {
    const [dbname, setDbname] = useState('')
    const [dbhost, setDbhost] = useState('')
    const [dbuser, setDbuser] = useState('')
    const [dbpass, setDbpass] = useState('')
    const [dbport, setDbport] = useState('')

    const fetchData = useCallback(async () => {
        if(!empresaId) return;
        const docRef = doc(db, 'empresas', empresaId)
        const r: any  = (await getDoc(docRef)).data()
        setDbname(r.dbname)
        setDbhost(r.dbhost)
        setDbuser(r.dbuser)
        setDbpass(r.dbpass)
        setDbport(r.dbport)
    }, [empresaId])

    useEffect(() => {
        fetchData()
    }, [fetchData])
    
    const handleSendConfig = useCallback(async (e: React.FormEvent) => {
        e.preventDefault()

        if(!dbname || !dbhost || !dbuser || !dbpass || !dbport) {
            toast.error('Todos os campos devem ser preenchidos')
            return
        }

        const docRef = doc(db, 'empresas', empresaId)
        try {
            await updateDoc(docRef, {
                dbname, dbhost, dbuser,
                dbpass, dbport
            })
            toast.success('Atualizado com sucesso')
        } catch (err: any) {
            toast.error(err)
        } finally {
            setDbname('')
            setDbhost('')
            setDbuser('')
            setDbpass('')
            setDbport('')
        }
    }, [dbhost, dbname, dbpass, dbport, dbuser, empresaId])

    return (
        <div style={{padding: 20}}>
            <h1>conexão banco de dados</h1>
            <form onSubmit={handleSendConfig}>
                <input
                    className="server-config-input"
                    type="text"
                    value={dbname}
                    placeholder="dbname"
                    onChange={(e) => setDbname(e.target.value)}/>
                <input
                    className="server-config-input"
                    type="text"
                    value={dbhost}
                    placeholder="dbhost"
                    onChange={(e) => setDbhost(e.target.value)}/>
                <input
                    className="server-config-input"
                    type="text"
                    value={dbuser}
                    placeholder="dbuser"
                    onChange={(e) => setDbuser(e.target.value)}/>
                <input
                    className="server-config-input"
                    type="password"
                    value={dbpass}
                    placeholder="dbpass"
                    onChange={(e) => setDbpass(e.target.value)}/>
                <input
                    className="server-config-input"
                    type="number"
                    value={dbport}
                    placeholder="dbport"
                    onChange={(e) => setDbport(e.target.value)}/>
                <br/>
                <button className="server-config-button" type="submit">Enviar</button>
            </form>
        </div>
    )
}

export default ServerConfiguration
