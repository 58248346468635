import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import EmpresaSearch from '../../components/EmpresaSearch'
import Sidebar from '../../components/Sidebar'
import { useAuth } from '../../hooks/useAuth'
import './styles.css'

function Empresa() {
    const { user } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if(!user) navigate('/login')
    //eslint-disable-next-line
    }, [user])

    return (
        <div className="empresa-container">
            <Sidebar/>
            <div className="search-container">
                <EmpresaSearch/>
            </div>
        </div>
    )
}

export default Empresa
