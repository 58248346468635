import { doc, getDoc } from 'firebase/firestore';
import { useCallback, useContext, useEffect, useState } from 'react';
import {EmpresaContext} from '../../contexts/EmpresaContext';
import { db } from '../../services/firebase';
import BackupItem from '../BackupItem';
import './styles.css'

interface BackupListProps {
    width?: string;
}

interface Backup {
    url: string;
    backupSize: number;
    finishTime: Date;
    startTime: Date;
    uploadFinish: any;
    uploadDate: Date;
}

function BackupList(props: BackupListProps) {
	const { empresaId } = useContext(EmpresaContext)
    const [backups, setBackups] = useState<Backup[]>()
    
    const fetchBackups = useCallback(async () => {
        if(!empresaId) return;
        const docRef = doc(db, 'empresas', empresaId)
        const r: any = (await getDoc(docRef)).data()
        setBackups(r.backups)
    }, [empresaId])

    useEffect(() => {
        fetchBackups()
    }, [fetchBackups])
    
    return (
        <div className="backuplist-container">
            <h1>Backups</h1>
            {
                backups &&
                backups.map((b, index) =>{
                    console.log(b)
                    return <BackupItem
                        key={index}
                        // top 10 gambiarra pq to com pressa
                        // achei essa solucao aqui https://stackoverflow.com/questions/51101388/find-second-occurrence-of-a-character-in-a-string
                        // pra poder dar uma reduzida no tamanho da string
                        // [VICTOR]: Mudei para url minúsculo pq no novo firebase vem minusculo
                        // id={b.URL.slice(b.URL.indexOf('/', (b.URL.indexOf('/'))+1))}
                        id={b.url}
                        // removido o .seconds
                        backupDate={(new Date(b.uploadFinish * 1000)).toLocaleString('pt-br')}
                        backupSize={Number((b.backupSize/1024/1024/1024).toFixed(4)).toLocaleString('pt-br') + ' Gb'}
                    />}
                )
            }
        </div>
    )
}

export default BackupList
