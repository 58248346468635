import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {Toaster} from 'react-hot-toast'
import LoginPage from './pages/Login'
import EmpresaPage from './pages/Empresa'
import HomePage from './pages/Home'
import './styles/global.css'

import './services/firebase'
import { AuthContextProvider } from './contexts/AuthContext'
import EmpresaContextProvider from './contexts/EmpresaContext'
import { useAuth } from './hooks/useAuth'
import Dashboard from './pages/Dashboard'
import NotFound from './pages/NotFound'

function App() {
  const { user } = useAuth()
  return (
    <div>
      <BrowserRouter>
        <AuthContextProvider>
		  <EmpresaContextProvider>
		  	<Routes>
		  	{/* check if user role is enduser, if positive send to dashboard */}
		  	<Route path="/" element={!user ? <EmpresaPage /> : <HomePage />} />
		  	<Route path="/dashboard/:endusercnpj" element={ !user ? <Dashboard /> : <HomePage />} />
		  	<Route path="/login" element={<LoginPage />} />
		  	<Route path="/empresa" element={<EmpresaPage />} />
		  	<Route path='*' element={<NotFound/>} />
		  	</Routes>
		  </EmpresaContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
      <Toaster />
    </div>
  )
}

export default App
