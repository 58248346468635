import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

import BackupList from '../../components/BackupList'
import BackupItem from '../../components/BackupItem'
import Panel from '../../components/Panel'

import './styles.css'
import Sidebar from '../../components/Sidebar'


export default function HomePage() {
    const { user, logOut } = useAuth()
    const navigate = useNavigate()

    if(!user) return (
        <div className="login-container">
            <h1>backup_shield.exe</h1>
            <button
                className="login-button"
                onClick={() => navigate('/login')}
            >
                login
            </button>
        </div>
    )

    return (
        <div className="page-container">
            <Sidebar/>
            <div className="dashboard-container">
                <div>
                    <div className="welcome-container">
                        <h1>Olá {user.email}</h1>
                        <button className="button-logout" onClick={logOut}>log out</button>
                    </div>
                    <br/>
                    <div className="management-container">
                        <Panel
                            kind='back'
                            width={1200}
                        >
                            <Panel
                                kind='front'
                                width={900}
                                className="show-backups"
                            >
                            </Panel>
                        </Panel>
                    </div>
                </div>
            </div>
        </div>
    )
}
